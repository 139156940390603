@font-face {
  font-family: "Buenos Aires";
  src: local("Buenos Aires Bold"),
       local("BuenosAires-Bold"),
       url("./fonts/BuenosAires-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B Bold"),
       local("EuclidCircularB-Bold"),
       url("./fonts/EuclidCircularB-Bold.otf") format("opentype");
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B Light"),
       local("EuclidCircularB-Light"),
       url("./fonts/EuclidCircularB-Light.otf") format("opentype");
  font-style: normal;
  font-weight: 300;
}
@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B Medium"),
       local("EuclidCircularB-Medium"),
       url("./fonts/EuclidCircularB-Medium.otf") format("opentype");
  font-style: normal;
  font-weight: 500;
}
@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B Regular"),
       local("EuclidCircularB-Regular"),
       url("./fonts/EuclidCircularB-Regular.otf") format("opentype");
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Euclid Circular B";
  src: local("Euclid Circular B Semibold"),
       local("EuclidCircularB-Semibold"),
       url("./fonts/EuclidCircularB-Semibold.otf") format("opentype");
  font-style: normal;
  font-weight: 600;
}